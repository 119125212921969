<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      />
    </v-card-title>
    <v-data-table
      class="datatable-with-row-click-action"
      :headers="headers"
      :items="markets"
      :search="search"
      :items-per-page="5"
      :loading="isLoading"
      @click:row="handlePreview"
    />
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "MarketsList",
  data() {
    return {
      isLoading: false,
      search: null,
      headers: [
        {
          text: "Name",
          value: "name"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      markets: state => state.api.management.market.markets
    })
  },
  mounted() {
    if (this.markets.length < 1) {
      this.handleGetMarkets();
    }

    this.$root.$on("refresh-market-list", this.handleGetMarkets);
  },
  beforeDestroy() {
    this.$root.$off("refresh-market-list", this.handleGetMarkets);
  },
  methods: {
    ...mapActions({
      getMarkets: "api/management/market/getMarkets"
    }),
    handleGetMarkets() {
      this.isLoading = true;
      this.getMarkets()
        .catch(error => {
          console.log(error);
          this.$notifier.notify({
            message: "Cannot query markets list",
            color: "error"
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleEdit(item) {
      console.log(item);
    },
    handlePreview(item) {
      this.$router.push({
        name: "PanelMarketsMarket",
        params: {
          id: item._id
        }
      });
      console.log(item);
    }
  }
};
</script>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
