<template>
  <div>
    <v-card-title>
      <navigation-breadcrumbs />
    </v-card-title>
    <v-card-text v-if="isLoading">
      <v-progress-circular :size="20" width="2" indeterminate />
      Please wait, loading data...
    </v-card-text>
    <v-card-text v-else>
      <v-row>
        <v-col>
          <v-row>
            <v-col>
              <v-btn
                color="success"
                @click="$router.push({ name: 'PanelMarketsNewMarket' })"
                :disabled="$route.name === 'PanelMarketsNewMarket'"
              >
                <v-icon small>mdi-plus</v-icon>
                New market
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <markets-list />
            </v-col>
            <v-col>
              <router-view />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import NavigationBreadcrumbs from "@/components/NavigationBreadcrumbs";
import MarketsList from "@/views/Panel/views/Markets/components/MarketsList";
import { mapActions, mapState } from "vuex";
export default {
  name: "Markets",
  components: { MarketsList, NavigationBreadcrumbs },
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      countries: state => state.api.misc.countries,
      languages: state => state.api.misc.languages
    })
  },
  created() {
    const requests = [];

    if (this.countries.length < 1) {
      requests.push(this.getCountries());
    }

    if (this.languages.length < 1) {
      requests.push(this.getLanguages());
    }

    if (requests.length > 0) {
      this.isLoading = true;
    }

    Promise.all(requests).finally(() => {
      this.isLoading = false;
    });
  },
  methods: {
    ...mapActions({
      getCountries: "api/misc/getCountries",
      getLanguages: "api/misc/getLanguages"
    })
  }
};
</script>
